import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getUserProfile, getTitle, getWorks, getEducation, getCertification, getSkills } from '../../../features/profileSlice'
import Spinner from '../../../Spinner'
import moment from 'moment';


const UserProfile = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const userId = queryParams.get("id")

    const {userProfile, titles, experience, education, certificate, skill} = useSelector((state)=>state.profile)

    const dispatch = useDispatch()

    useEffect(async ()=>{
        await dispatch(getUserProfile(userId))
        await dispatch(getTitle(userId))
        await dispatch(getWorks(userId))
        await dispatch(getEducation(userId))
        await dispatch(getCertification(userId))
        await dispatch(getSkills(userId))

    },[dispatch])



    console.log(titles)

    if(!userProfile || !titles || !experience || !education || !certificate || !skill)
    return(<Spinner />)


      return (
     
           <div>
           <Card className='job-card mb-4 border-0'>
    
    <div className='card-body'>
    <div>
     <h3 className='display-6 fw-bold'> <span className='text-danger'>{userProfile[0].firstName} {userProfile[0].lastName}</span></h3>
     <h5 className='lead'>{!titles ? '' : titles.title}</h5>
     <p>{!titles ? '' : titles.about}</p>
    </div>
    </div>
</Card>

<Card className='job-card mb-4 border-0'>
                <div className='card-header bg-light border-0'>
                    <div className='d-flex float-start'>
                        Personal Information
                    </div>

                </div>
                <div className='card-body'>
                    <div className='row'>
                        <Col md={6} sm={12} className='mb-2'>
                            <small className='text-muted'><i className='fa fa-envelope'></i> Email Adress </small>
                            <p className='lead'>{userProfile[0].email}</p>

                        </Col>
                        <Col md={6} sm={12} className='mb-2'>
                            <small className='text-muted'><i className='fa fa-phone'></i> Phone: </small>
                            <p className='lead'>{userProfile[0].phone}</p>
                        </Col>

                        <Col md={6} sm={12} className='mb-2'>
                            <small className='text-muted'><i className='fa fa-money'></i> Salary Expectation: </small>
                            <p className='lead'>{userProfile[0].expectedPay}</p>
                        </Col>

                        <Col md={6} sm={12} className='mb-2'>
                            <small className='text-muted'><i className='fa fa-globe'></i> Nationality: </small>
                            <p className='lead'>{userProfile[0].nationality}</p>
                        </Col>

                    </div>

                </div>
            </Card>

            <Card className='job-card mb-4 border-0'>
                <div className='card-header bg-light border-0'>
                    <div className='d-flex float-start'>
                        Experience
                    </div>

                </div>
                <div className='card-body'>
                    <Row>
                        {experience=== null ? 'No job history added' : !experience ? 'loading...'  : experience.map(items =>(
                            <>
                            <Col md={12} sm={12} className='mb-2' key={items.id}>
                                <p className='lead fw-bold m-0'  >{items.company}</p>
                                <small className='text-muted'>{items.workTitle} <span className='bg-light text-danger'>{moment(items.startDate).format('LL')} to {moment(items.endDate).format('LL')}</span> </small>
                                <hr className='border-danger' />
                            </Col>
                            </>


                       ))}
                        
                    </Row>

                </div>
            </Card>

            <Card className='job-card mb-4 border-0'>
        <div className='card-header bg-light border-0'>
            <div className='d-flex float-start'>
                Educations
            </div>
           
        </div>
        <div className='card-body'>
            
           <div className='row'>
            {education == undefined ? <div>No education history added</div> ? !education : 'loading...'   : education.map(items =>(
                <>
                <Col className='mb-3' md={12} sm={6} key={items.id}>
                
                    <p className='lead fw-bold m-0' >{items.institution}</p>
                    <small className='text-muted' >{items.course}<span className='bg-light text-danger'> ({moment(items.startYear).format('YYYY')} - {moment(items.startYear).format('YYYY')}) </span></small>
                    <hr className='border-danger' />
                </Col>
                </>
            ))}
            
              </div>  
               
                
            </div>
       
        </Card>

        <Card className='job-card mb-4 border-0'>
        <div className='card-header bg-light border-0'>
            <div className='d-flex float-start'>
                Certifications
            </div>
            
        </div>
        <div className='card-body'>
        <Row>
                
                   
                    
                {certificate == undefined ? <div>No Certification added</div> ? !certificate : 'loading...'   : certificate.map(items =>(
                <> 
                <Col md={12} sm={12} className='mb-2' key={items.id}>
                    
                        <p className='lead fw-bold m-0'>{items.certName}</p>
                        <small className='text-muted'>{items.provider}<span className='bg-light text-danger'> {moment(items.getDate).format('LL')}</span></small>
                        <p><small className='text-muted'>{items.certNumber}</small> </p> 
                    <hr className='border-danger' />
                    </Col>
                    
                </>
                 
                
             
                ))}
                

                
               
                
            </Row>
       
        </div>
    </Card>

    <Card className='job-card mb-4 border-0'>
        <div className='card-header bg-light border-0'>
            <div className='d-flex float-start'>
                Skills
            </div>
            
        </div>
        <div className='card-body'>
            <Row>
            {skill == undefined ? <div>No skill added</div> ? !skill : 'loading...'   : skill.map(items =>(
                
                <div className='mb-2 col-md-3 col-sm-6'  key={items.id}>
                    <p className='lead text-danger bg-light m-0 text-center rounded' >{items.skills}</p>
                </div>
                
            ))}
               
               
                
            </Row>
       
        </div>
    </Card>
           </div>
    )
}

export default UserProfile