import {createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { extractErrorMessage } from "../utils"

const url = process.env.REACT_APP_BACKEND

const initialState = {
    userProfile: null,
    experience: null,
    education: null,
    skill: null,
    certificate: null,
    titles:null,
    convo:null,
    feedback:null
  }
   //delete user
   export const deleteUser = createAsyncThunk(
    'admin/deleteUser',
    async(id, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.admin.token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        const response = await axios.delete(`${url}/admin/removeUser/${id}`, config)
        return response.data
        
      } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error))
      }
    }
  )
  //delete jobs
  export const deletJobs = createAsyncThunk(
    'admin/deletJobs',
    async(id, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.admin.token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        const response = await axios.delete(`${url}/admin/deleteJobs/${id}`, config)
        return response.data
        
      } catch (error) {
        return thunkAPI.rejectWithValue(extractErrorMessage(error))
      }
    }
  )
//fetch feedbacks
export const getFeedbacks = createAsyncThunk(
  'admin/getFeedbacks',
  async(_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(`${url}/admin/getFeedbacks`, config)
      return response.data
      
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

  //get conversation
export const getConvo = createAsyncThunk(
  'users/getConvo',
  async(email, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(`${url}/messages/getConversation/${email}`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
  
  //add Title
  export const addTitle = createAsyncThunk(
    'users/addTitle',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.admin.token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        return await axios.post(`${url}/users/addAbout`, data, config)
  
      } catch (error) {
        return thunkAPI.rejectWithValue(error)
      }
  
    });
  
  //get Tile and about
  export const getTitle = createAsyncThunk(
    'users/getTitle',
    async (userId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.admin.token
         
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        const response = await axios.get(`${url}/users/getAbout/${userId}`, config)
        return response.data[0]
      } catch (error) {
        return thunkAPI.rejectWithValue(error)
      }
  
    });
  
  
  
  //get Certification
  export const getCertification = createAsyncThunk(
    'users/getCertification',
    async (userId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.admin.token
         
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        const response = await axios.get(`${url}/users/getCerts/${userId}`, config)
        return response.data
      } catch (error) {
        return thunkAPI.rejectWithValue(error)
      }
  
    });
  
  
  
  //get Education
  export const getSkills = createAsyncThunk(
    'users/getSkills',
    async (userId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.admin.token
          
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        const response = await axios.get(`${url}/users/getSkills/${userId}`, config)
        return response.data
      } catch (error) {
        return thunkAPI.rejectWithValue(error)
      }
  
    });
  
  
    
  //get Education
  export const getEducation = createAsyncThunk(
    'users/getEducation',
    async (userId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.admin.token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        const response = await axios.get(`${url}/users/getEducation/${userId}`, config)
        return response.data
      } catch (error) {
        return thunkAPI.rejectWithValue(error)
      }
  
    });
  
   
  
  //get all experience
  export const getWorks = createAsyncThunk(
    'users/works',
    async (userId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.admin.token
         
  
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        const response = await axios.get(`${url}/users/getExperience/${userId}`, config)
        return response.data.rows
      } catch (error) {
        return thunkAPI.rejectWithValue(error)
      }
  
    });
  
  //get user profile
  export const getUserProfile = createAsyncThunk(
    'admin/userprofile',
    async (userId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.admin.token
          
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        const response = await axios.get(`${url}/admin/getProfile/${userId}`, config)
        return response.data
      } catch (error) {
        return thunkAPI.rejectWithValue(error)
      }
  
    });
  
    
  
  
  export const profileSlice = createSlice({
    name: 'profile',
    initialState,
  
    reducers: {},
    extraReducers: (builder) => {
      (builder)
  
        .addCase(getUserProfile.fulfilled, (state, action) => {
          state.userProfile = action.payload
        })
        .addCase(getWorks.fulfilled, (state, action) => {
          state.experience = action.payload
        })
        .addCase(getEducation.fulfilled, (state, action) => {
          state.education = action.payload
        })
        .addCase(getSkills.fulfilled, (state, action) => {
          state.skill = action.payload
        })
        .addCase(getCertification.fulfilled, (state, action) => {
          state.certificate = action.payload
        })
        .addCase(getTitle.fulfilled, (state, action) => {
          state.titles = action.payload
        })
        .addCase(getConvo.fulfilled, (state, action)=>{
          state.convo = action.payload
        })
        .addCase(getFeedbacks.fulfilled, (state, action) => {
          state.feedback = action.payload
        })
  
  
  
  
    }
  })
  
  export default profileSlice.reducer