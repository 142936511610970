import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { parseCv, getAdminCv, getJobs, updateInterviewAdmin, addInterview, rejectAdmin, hiredAdmin } from '../../../features/jobSlice'
import Spinner from '../../../Spinner'
import ReactPaginate from 'react-paginate'

const AdminCvParsing = () => {
  const {adminCv, jobs} = useSelector((state)=>state.job)
    const [show, setShow] = useState(false)
    const [showInterview, setShowInterview] = useState(false)
    const [userEmail, setUserEmail] = useState('')
    const [startTime, setTime] = useState('')
    const [dateIn, setDate] = useState('')
    const [address, setAddress] = useState('')
    const [notes, setNotes] = useState('')
    const [jobId, setJobId] = useState('')
    const [userId, setUserId] = useState('')
    const [id, setId] = useState('')
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1);

  //handle pagination
    const handlePageChange = ({ selected }) => {
     setCurrentPage(selected)
   };

   //open and close modals
    const handleClose = () => setShow(false)
    const handleOpen = () => setShow(true)

//close interview modal
    const handleCloseInterview = () => setShowInterview(false)

   // Open interview modal
   const handleOpenInterview = async (userid, email, id) => {
      setUserEmail(email);
    setShowInterview(true);
    setUserId(userid);
    setId(id)
   
     // Check if profile[0] exists before accessing userId
  };

    const[applicantFirstName, setFirstName] = useState('')
    const[applicantLastName, setLastName] = useState('')
    const[keyword, setKeywords] = useState('')
    const [email, setEmail] = useState('')
    const [cv, setCv] = useState('')
    const[jobTitle, setJobTitle] = useState('')
    const[company, setCompany] = useState('')
    const [hasExecuted, setHasExecuted] = useState(false);

    //schedule interview
    const sendInterview = (e) => {
      e.preventDefault()
      setShowInterview(false)
      let data = {
        userEmail:userEmail,
        jobId: jobId,
        timeStart:startTime,
        dateIn:dateIn,
        address:address,
        notes:notes,
        userId:userId,
        id:id
      }
      dispatch(addInterview(data)).unwrap().then(()=>{
        dispatch(updateInterviewAdmin(data))
        setHasExecuted(!hasExecuted)
      toast.success(`interview invite sent`)
      }).catch(toast.error)
     }

    //parse cv
    const parseTheCv = () =>{
        let data = new FormData()
        data.append('applicantFirstName', applicantFirstName)
        data.append('applicantLastName', applicantLastName)
        data.append('keyword', keyword)
        data.append('email', email)
        data.append('cv', cv)
        data.append('jobTitle', jobTitle)
        data.append('company', company)
        dispatch(parseCv(data)).unwrap().then(()=>{
            toast.success('Cv uploaded and parsed')
            setShow(false)
        }).catch(toast.error)
        
    }

    const hireApplicant=(id) => {
      dispatch(hiredAdmin(id)).unwrap().then(()=>{
        toast.warning('Applicant status updated')
      }).catch(toast.error)
      setHasExecuted(!hasExecuted)

    }

    const reject = (id) => {
      dispatch(rejectAdmin(id)).unwrap().then(()=>{
        toast.warning('Applicant status updated to rejected')
      }).catch(toast.error)
      setHasExecuted(!hasExecuted)
    }
    useEffect(() => {
      async function fetchDataAsync() {
        await dispatch(getAdminCv({page:currentPage}));
        await dispatch(getJobs());
      }
      fetchDataAsync();
    }, [dispatch, show, hasExecuted, currentPage]);
  

    if(!adminCv || !jobs )
    return(
      <Spinner />
    )

    console.log(userId)
    var x = 0
  return (
    <>
    <div>
        <Button className='btn btn-warning mb-3 float-right' onClick={handleOpen}>Add a cv</Button> 
    </div>
       
        <table className="table shadow-hover table-striped card-table w-100" >
                                       
                                       <thead>
                                        <tr>
                                          <th>S/N</th>
                                          <th>Name</th>
                                          <th>Keywords</th>
                                          <th>Email</th>
                                          <th>Job Title</th>
                                          <th>Company</th>
                                          <th>Percentage Match</th>
                                          <th>Matched Words</th>
                                          <th>Status</th>
                                          <th>Actions</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                       {adminCv.data.count === 0 ? 'No data available' : adminCv.data.rows.map((items)=>(
                                            <tr key={items.id}>
                                              <td>{++x}</td>
                                              <td>{items.applicantFirstName}{' '}{items.applicantLastName}</td>
                                              <td>{items.keyword}</td>
                                              <td>{items.email}</td>
                                              <td>{items.jobTitle}</td>
                                              <td>{items.company}</td>
                                              <td>{items.percentMatch}%</td>
                                              <td>{items.matchedKeywords}</td>
                                              <td>{items.applicantStatus}</td>
                                              <td>
                                              <div className='btn-group'>
                                              <i className='fa fa-times-circle mx-2 my-2 text-danger' onClick={()=>reject(items.id)}></i>
                                              <a href={`/adminCv?userId=${items.id}`} className='my-2 mx-2'><i className='fa fa-file-text text-warning'></i></a>
                                              {items.applicantStatus === 'Interview' ?
                                              <i  className='fa fa-check-square text-success mx-2 my-2' onClick={()=>hireApplicant(items.id)}></i> 
                                              :
                                              <i  className='fa fa-calendar-times my-2 mx-2 text-info' onClick={()=>handleOpenInterview(items.userId, items.email, items.id)}></i>
                                              }
                                              </div>
                                              </td>
                                          </tr>
                                        ))}
                                        </tbody>
                                        </table>
                                        <ReactPaginate
                pageCount={adminCv.totalPages}
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                forcePage={currentPage}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                containerClassName={'pagination'}
                activeClassName={'active'}
                pageLinkClassName={'page-link'} />

        <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Upload Cv for parsing </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            
              <div className='col-md-6 col-sm-12'>
                <div className='form-group'>
                <input type="text" placeholder='Applicant First Name' className='form-control' required onChange={(e)=>setFirstName(e.target.value)}/>
              </div>
              </div>

              <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' placeholder='Applicant Last Name' required onChange={(e)=>setLastName(e.target.value)}  />
              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' placeholder='Applicant Email' required onChange={(e)=>setEmail(e.target.value)}  />
              </div>
            </div>
            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' placeholder='Company' required onChange={(e)=>setCompany(e.target.value)}  />
              </div>
            </div>

            <div className='col-md-12 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' placeholder='Job Title' required onChange={(e)=>setJobTitle(e.target.value)}  />
              </div>
            </div>

           
            <div className='col-md-12 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' placeholder='keywords seperate with comma' required onChange={(e)=>setKeywords(e.target.value)} />

              </div>
            </div>

            
            <div className='col-md-12 col-sm-12 col-lg-12 mb-3'>
                        <label className='mb-1'>Upload CV</label>
                        <input type='file' accept='.pdf' name='cv' onChange={(e) => setCv(e.target.files[0])} className='form-control' />
                        <small>.pdf 2mb</small>
                      </div>
        
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={parseTheCv}>
            Parse Cv
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showInterview} onHide={handleCloseInterview} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Schedule Interview for {userEmail} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            
              <div className='col-md-6 col-sm-12'>
                <div className='form-group'>
                <input type="date" className='form-control' onChange={(e)=>setDate(e.target.value)} />

              </div>
              </div>

              <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="time" className='form-control' onChange={(e)=>setTime(e.target.value)} />

              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' placeholder='Interview link or Address' onChange={(e)=>setAddress(e.target.value)} />
              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' placeholder='Extra Information' onChange={(e)=>setNotes(e.target.value)} />
              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <select className='form-control form-select' onChange={(e)=>setJobId(e.target.value)}>
                  <option>Select Job to be interviewed for</option>
                  {jobs.data.map((jobItem)=>(
                    <option value={jobItem.id} key={jobItem.id}>{jobItem.jobTitle}</option>
                  ))}
                  </select>
              </div>
            </div>

          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={sendInterview}>
            Schedule
          </Button>
        </Modal.Footer>
      </Modal>    

    </>
  )
}

export default AdminCvParsing