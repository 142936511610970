import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getApplicants, addInterview, reject } from '../../../features/jobSlice'
import Spinner from '../../../Spinner'

const Applicants = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const jobId = queryParams.get("id")
  const { applicants } = useSelector((state) => state.job)
  var x = 0
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getApplicants({ jobId }))
  }, [])

  const [users, setUsers] = useState(null)
  const [usersId, setusersId] = useState(null)
  const [startTime, setTime] = useState('')
  const [dateIn, setDate] = useState('')
  const [address, setAddress] = useState('')
  const [notes, setNotes] = useState('')



  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (value, userId) => {
    setShow(true)
    setUsers(value)
    setusersId(userId)
  }

  const sendInterview = (e) => {
    e.preventDefault()
    setShow(false)
    let data = {
      userEmail: users,
      userId: usersId,
      jobId: jobId,
      timeStart: startTime,
      dateIn: dateIn,
      address: address,
      notes: notes
    }
    dispatch(addInterview(data)).unwrap().then(() => {
      toast.success(`interview invite sent for ${users}`)
    }).catch(toast.error)
  }

  //reject user
  const rejects = (id) => {
    let data = {
      id: id
    }
    dispatch(reject(data)).unwrap().then(() => {
      toast.warning('Applicant status updated to rejected')
    }).catch(toast.error)

  }


  if (!applicants)
    return (<Spinner />)



  // console.log(applicants)

  return (
    <>
      <h4 className="fs-20 text-black display-5 fw-bold">
        All Jobs
      </h4>

      <table className="table shadow-hover table-striped card-table w-100" >

        <thead>
          <tr>
            <th>S/N</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mode</th>
            <th>% Match</th>
            <th>Cover Letter</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {applicants.length == 0 ? 'No applicant yet' : applicants.rows.map((items) => (
            <tr key={items.id}>
              <td>{++x}</td>
              <td>{items.ids.lastName} {items.ids.firstName}</td>
              <td>{items.ids.email}</td>
              <td>{items.applicationMode}</td>
              <td>{items.percentMatch}%</td>
              <td>{items.coverLetter}</td>
              <td>{items.applicantStatus}</td>
              <td>
                <div className='btn-group'>
                  <i className='fa fa-times-circle mx-2 my-2 text-danger' onClick={() => rejects(items.id)}></i>
                  {items.applicationMode === 'withProfile' ?
                    <a href={`/userProfile?id=${items.userId}`} className='mx-2 my-2'><i className='fa fa-eye text-warning'></i></a>
                    : items.applicationMode === 'withCv' ? <a href={`/userCv?userId=${items.userId}`} className='fa fa-file-text mx-2 my-2 text-warning'></a> : ''}
                  {items.applicantStatus === 'Interview' ? <i className='fa fa-check-square text-success mx-2 my-2' onClick={() => hireApplicant(items.id)}></i> : items.applicantStatus === 'pending' || 'rejected' ?
                    <i className='fa fa-calendar-times text-info mx-2 my-2' onClick={() => handleShow(items.ids.email, items.userId)}></i> : ''
                  }

                </div>

              </td>
            </tr>
          ))}
        </tbody>




      </table>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Schedule Interview for {users} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="date" className='form-control' onChange={(e) => setDate(e.target.value)} />

              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="time" className='form-control' onChange={(e) => setTime(e.target.value)} />

              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' placeholder='Interview link or Address' onChange={(e) => setAddress(e.target.value)} />
              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' placeholder='Extra Information' onChange={(e) => setNotes(e.target.value)} />
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={sendInterview}>
            Schedule
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Applicants