import React, { Fragment, useEffect } from "react";
import { Dropdown, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getJobs, getAllApplication, getAllUser, getCategory } from "../../../features/jobSlice";

// imeges
import bit_1 from "../../../images/pictures/jobs.png";
import bit_2 from "../../../images/pictures/user.png";
import bit_3 from "../../../images/pictures/opened-folder.png";
import bit_4 from "../../../images/pictures/applicants.png";

// Chart
import ActivityLine from "../Dhrev/Home/chart/ActivityLine";
import ActivityLine2 from "../Dhrev/Home/chart/ActivityLine2";
import ActivityLine3 from "../Dhrev/Home/chart/ActivityLine3";
import Donut from "../Dhrev/Home/chart/Donut";

// Slider
import CardSlider from "../Dhrev/Home/slider/CardSlider";
import Contacts from "../Dhrev/Home/slider/Contacts";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../Spinner";
import moment from "moment";

const Home = () => {
   const dispatch=useDispatch()
   const limit = 6

   const{jobs, users, category, applications } = useSelector((state)=>state.job)
   useEffect(()=>{
      dispatch(getJobs({pageSize:10}))
      dispatch(getAllUser({pageSize:10}))
      dispatch(getCategory({pageSize:10}))
      dispatch(getAllApplication({pageSize:10}))

   },[dispatch])


   if(!jobs || !users || !category || !applications)
   return(<Spinner />)

   return (
      <Fragment>
         <>
           
            <div className="row">
               <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                  <a href="/jobs">
                  <div className="card overflow-hidden">
                     <div className="card-header border-0 pb-0">
                        <div className="mr-auto pt-5">
                           <h2 className="text-black mb-2 font-w600">
                           {!jobs? '' : jobs.totalItems}
                           </h2>
                           <p>Total Job Posted</p>
                        </div>
                        <img src={bit_1} alt="" />
                     </div>
                     
                  </div>
                  </a>
               </div>
               <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
               <a href="/all-users">
                  <div className="card overflow-hidden">
                     <div className="card-header border-0 pb-0">
                     <div className="mr-auto pt-5">
                           <h2 className="text-black mb-2 font-w600">{!users? '' : users.totalItems}</h2>
                           <p className="mb-1 fs-13">
                             Total Users
                           </p>
                        </div>
                        <img src={bit_2} alt="" />
                     </div>
                    
                  </div>
                  </a>
               </div>
               <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
               <a href="/category">
                  <div className="card overflow-hidden">
                     <div className="card-header border-0 pb-0">
                     <div className="mr-auto pt-5">
                           <h2 className="text-black mb-2 font-w600">{!category? '' : category.data.count}</h2>
                           <p className="mb-1 fs-13">
                              Total Job categories
                           </p>
                        </div>
                        <img src={bit_3} alt="" />
                     </div>
                    
                  </div>
                  </a>
               </div>
               <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                  <a href="/applications">
                  <div className="card overflow-hidden">
                     <div className="card-header border-0 pb-0">
                         <div className="mr-auto pt-5">
                           <h2 className="text-black mb-2 font-w600">
                           {!applications? '' : applications.data.count}
                           </h2>
                           <p className="mb-1 fs-13">
                             Total Applications
                           </p>
                        </div>
                        <img src={bit_4} alt="" />
                     </div>
                   
                  </div>
                  </a>
               </div>
               <div className="col-xl-6 col-xxl-12 col-lg-12">
                  <div className="card">
                     <div className="card-header d-sm-flex d-block pb-0 border-0">
                        <div>
                           <h4 className="text-black fs-20">Application Trends</h4>
                           <p className="fs-13 mb-0">
                             Overall application trend for the year 
                           </p>
                        </div>
                       
                     </div>
                     <div className="card-body" id="user-activity">
                        <Tab.Container defaultActiveKey="all">
                           <div className="d-flex flex-wrap justify-content-between mb-5">
                              <div className="card-action card-tabs  mt-3 mt-sm-0">
                                 <Nav className="nav nav-tabs" role="tablist">
                                    <Nav.Item className="nav-item">
                                       <Nav.Link eventKey="all">Hired</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                       <Nav.Link eventKey="btc">
                                         
                                          Rejected
                                       </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item" >
                                       <Nav.Link eventKey="ethereum">
                                         Interview
                                       </Nav.Link>
                                    </Nav.Item>
                                 </Nav>
                              </div>
         
                           </div>
                           <Tab.Content
                              className="tab-content"
                              id="myTabContent"
                           >
                              <Tab.Pane eventKey="all">
                                 <ActivityLine />
                              </Tab.Pane>
                              <Tab.Pane eventKey="btc">
                                 <ActivityLine2 />
                              </Tab.Pane>
                              <Tab.Pane eventKey="ethereum">
                                 <ActivityLine3 />
                              </Tab.Pane>
                           </Tab.Content>
                        </Tab.Container>
                     </div>
                  </div>
               </div>
               <div className="col-xl-6 col-xxl-6 col-lg-6 col-sm-12">
                  <div className="card">
                    
                        <div className="card-header d-block d-sm-flex border-0">
                           <div>
                              <h4 className="fs-20 text-black">
                                 Recent Applications
                              </h4>
                              
                           </div>
                          
                        </div>
                        <div className="card-body tab-content p-0">
                          
                                 <div className="table-responsive">
                                    <table className="table shadow-hover card-table">
                                       <tbody>
                                          {applications === null ? 'no application' : applications.data.rows.map((items)=>(
                                              <tr key={items.id}>
                                              <td>
                                                 <span className="bgl-success p-3 d-inline-block">
                                                    <svg
                                                       width={29}
                                                       height={29}
                                                       viewBox="0 0 29 29"
                                                       fill="none"
                                                       xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                       <path
                                                          d="M23.6186 15.7207L23.6186 15.7207L23.6353 22.6289C23.6354 22.6328 23.6354 22.6363 23.6353 22.6396M23.6186 15.7207L23.1353 22.6341L23.6353 22.635C23.6353 22.6481 23.6347 22.6583 23.6345 22.6627L23.6344 22.6642C23.6346 22.6609 23.6351 22.652 23.6353 22.6407C23.6353 22.6403 23.6353 22.64 23.6353 22.6396M23.6186 15.7207C23.6167 14.9268 22.9717 14.2847 22.1777 14.2866C21.3838 14.2885 20.7417 14.9336 20.7436 15.7275L20.7436 15.7275L20.7519 19.1563M23.6186 15.7207L20.7519 19.1563M23.6353 22.6396C23.6329 23.4282 22.9931 24.0705 22.2017 24.0726C22.2 24.0726 22.1983 24.0727 22.1965 24.0727L22.1944 24.0727L22.1773 24.0726L15.2834 24.056L15.2846 23.556L15.2834 24.056C14.4897 24.054 13.8474 23.4091 13.8494 22.615C13.8513 21.8211 14.4964 21.179 15.2903 21.1809L15.2903 21.1809L18.719 21.1892L5.53639 8.0066C4.975 7.44521 4.975 6.53505 5.53639 5.97367C6.09778 5.41228 7.00793 5.41228 7.56932 5.97367L20.7519 19.1563M23.6353 22.6396C23.6353 22.6376 23.6353 22.6356 23.6353 22.6336L20.7519 19.1563M22.1964 24.0726C22.1957 24.0726 22.1951 24.0726 22.1944 24.0726L22.1964 24.0726Z"
                                                          fill={items.applicantStatus === 'hired' ? "#2BC155" : items.applicantStatus==='interview' ? "#FFAB2D" : items.applicantStatus === 'rejected' ? "#FF2E2E" : ''}
                                                          stroke={items.applicantStatus === 'hired' ? "#2BC155" : items.applicantStatus==='interview' ? "#FFAB2D" : items.applicantStatus === 'rejected' ? "#FF2E2E" : ''}
                                                       />
                                                    </svg>
                                                 </span>
                                              </td>
                                              <td>
                                                 <div className="font-w600 wspace-no">
                                                   {!items.ids ? '' : items.ids.firstName}{' '}{!items.ids ? '' :items.ids.lastName}
                                                     
                                                 </div>
                                              </td>
                                              <td className="font-w500">
                                                 {!items.ids ? '' : items.jids.jobTitle}
                                              </td>
                                              <td className="font-w600 text-center">
                                                {!items.ids ? '' : items.percentMatch}
                                              </td>
                                              <td className={items.applicantStatus === 'hired' ? "text-success" : items.applicantStatus === 'interview' ? "text-warning" : items.applicantStatus ==='rejected' ? "text-danger" : '' }>
                                                 
                                                   {items.applicantStatus}
                                                
                                              </td>
                                           </tr>
                                          ))}
                                         
                                       </tbody>
                                    </table>
                                 </div>
                             
                        </div>
                
                  </div>
               </div>
               <div className="col-xl-12 col-xxl-12 col-lg-12">
                  <div className="card">
                     <Tab.Container defaultActiveKey="monthly">
                        <div className="card-header d-block d-sm-flex border-0">
                           <div>
                              <h4 className="fs-20 text-black">
                                 Recent Jobs Posted
                              </h4>
                           </div>
                          
                        </div>
                        <div className="card-body tab-content p-0">
                        
                                 <div className="table-responsive">
                                    <table className="table shadow-hover  card-table">
                                       <thead>
                                          <tr className="text-muted">
                                             <th>Job Title</th>
                                             <th>Posted</th>
                                             <th>Location</th>
                                             <th>Company</th>
                                             <th>Type</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          {jobs.data.map((itemJob)=>(
                                              <tr key={itemJob.id}>
                                             
                                             <td>
                                                <div className="font-w600">
                                                   
                                                  {itemJob.jobTitle}
                                                </div>
                                             </td>
                                             <td className="font-w500">
                                               {moment(itemJob.createdAt).fromNow()}
                                             </td>
                                             <td className="font-w600 text-center">
                                               {itemJob.jobLocation}
                                             </td>
                                             <td>
                                               {itemJob.company}
                                             </td>

                                             <td>
                                               {itemJob.jobType}
                                             </td>
                                          </tr>
                                          ))}
                                         
                                       </tbody>
                                    </table>
                                 </div>
                              
                        </div>
                     </Tab.Container>
                  </div>
               </div>
              
             
            </div>
         </>
      </Fragment>
   );
};

export default Home;
